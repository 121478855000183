import { useParams } from 'react-router-dom';
import { useState, useCallback } from 'react';
import { useAsyncEffect, useWillUnmount, useDebounce } from 'rooks';
import { useDispatch, useSelector } from 'react-redux';
import { useRollbar } from '@rollbar/react';
import { createSlice } from '@reduxjs/toolkit';
import { calculationWorker } from './_worker/worker';
import { useEstimateCostFields } from '@/data/estimateCostField';
import { useEstimateDesignFields } from '@/data/estimateDesignField';
import { useEstimateCostBucketGroups } from '@/data/estimateCostBucketGroup';
import { useEstimateCostBuckets } from '@/data/estimateBucket';
import { useEstimateCostFieldGroups } from '@/data/estimateCostFieldGroup';
import { useEstimateComps } from '@/data/estimateComp';
import { useEstimateCompCostBuckets } from '@/data/estimateCompCostBucket';
import { useEstimateCompCostFields } from '@/data/estimateCompCostField';
import { useEstimateDetailedBudgetStructure, useEstimate, usePutEstimateBulkUpdateFields } from '@/data/estimate';
import { useEstimateEsclationRates } from '@/data/estimateEscalationRate';
import { useEstimateIndirectCosts } from '@/data/estimateIndirectCost';
import { useEstimateCostSubFields } from '@/data/estimateCostSubField';
import { useEstimateInputProgrammingConfigGroups } from '@/data/estimateInputProgrammingConfigGroup';
import { useFormulas } from '@/data/formula';

const useCalculations = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const rollbar = useRollbar();
  const params = useParams();
  const estimate = useEstimate(params.estimateUid);
  const estimateComps = useEstimateComps(params.estimateUid);
  const estimateDesignFields = useEstimateDesignFields(params.estimateUid);
  const estimateCostFields = useEstimateCostFields(params.estimateUid);
  const estimateCostBucketGroups = useEstimateCostBucketGroups(params.estimateUid);
  const estimateCostFieldGroups = useEstimateCostFieldGroups(params.estimateUid);
  const estimateCostBuckets = useEstimateCostBuckets(params.estimateUid);
  const estimateCompCostBuckets = useEstimateCompCostBuckets(params.estimateUid);
  const estimateCompCostFields = useEstimateCompCostFields(params.estimateUid);
  const estimateDetailedBudgetStructure = useEstimateDetailedBudgetStructure(params.estimateUid);
  const estimateEscalationRates = useEstimateEsclationRates(params.estimateUid);
  const estimateIndirectCosts = useEstimateIndirectCosts(params.estimateUid);
  const estimateCostSubFields = useEstimateCostSubFields(params.estimateUid);
  const formulas = useFormulas(params.estimateUid);
  const estimateProgrammingConfigGroups = useEstimateInputProgrammingConfigGroups(params.estimateUid);
  const estimateCostFieldCalculations = useSelector((state) => state.estimateCalculations.field);
  const estimateSubFieldCalculations = useSelector((state) => state.estimateCalculations['sub-field']);
  const estimateDesignFieldCalculations = useSelector((state) => state.estimateCalculations.formula['design-field']);
  const designFieldUpdateCount = useSelector((state) => state.estimateCalculations.designFieldUpdateCount);
  const putEstimateBulkUpdateFields = usePutEstimateBulkUpdateFields();

  // When the hook unmounts, we can reset the estimate calculations
  useWillUnmount(() => {
    dispatch(actions.resetEstimate());
  });

  const updateDesignFieldsSWRState = async (updatedDesignFields) => {
    if (updatedDesignFields.length === 0) {
      return;
    }

    await estimateDesignFields.mutate((current) => {
      const newContent = { ...current.content };
      const updatedFields = Object.fromEntries(
        updatedDesignFields.map((designField) => [
          designField.estimateDesignFieldUid,
          {
            ...newContent[designField.estimateDesignFieldUid],
            value: designField.value
          }
        ])
      );
      Object.assign(newContent, updatedFields);
      return { ...current, content: newContent };
    }, false);
  };

  const handleSaveFields = async (updatedDesignFields, updatedCostFields, updatedCostSubFields) => {
    if (updatedDesignFields.length === 0 && updatedCostFields.length === 0 && updatedCostSubFields.length === 0) {
      return;
    }

    try {
      await putEstimateBulkUpdateFields(params.estimateUid, {
        designFields: updatedDesignFields,
        costFields: updatedCostFields,
        costSubFields: updatedCostSubFields
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      rollbar.error(err);
    }
  };

  // Define the dependencies here, since they are used twice below
  const dependencies = [
    estimate.data,
    estimateComps.data,
    designFieldUpdateCount,
    estimateCostFields.data,
    estimateCostBucketGroups.data,
    estimateCostFieldGroups.data,
    estimateCostBuckets.data,
    estimateCompCostBuckets.data,
    estimateCompCostFields.data,
    estimateDetailedBudgetStructure.data,
    estimateEscalationRates.data,
    estimateIndirectCosts.data,
    estimateCostSubFields.data,
    estimateProgrammingConfigGroups.data,
    formulas.data
  ];

  const runCalculations = useCallback(async () => {
    setIsError(false);

    try {
      // Create a copy of the old data so we can do a diff after the calculations below
      const prevEstimateCostFieldCalculations = { ...estimateCostFieldCalculations };
      const prevEstimateDesignFieldCalculations = { ...estimateDesignFieldCalculations };
      const prevEstimateCostSubFieldCalculations = { ...estimateSubFieldCalculations };

      // Run the actual calculations inside of the worker
      const calculationResult = await calculationWorker.runCalculations({
        estimate: estimate.data,
        estimateComps: estimateComps.data,
        estimateDesignFields: estimateDesignFields.data,
        estimateCostFields: estimateCostFields.data,
        estimateCostBucketGroups: estimateCostBucketGroups.data,
        estimateCostFieldGroups: estimateCostFieldGroups.data,
        estimateCostBuckets: estimateCostBuckets.data,
        estimateCompCostBuckets: estimateCompCostBuckets.data,
        estimateCompCostFields: estimateCompCostFields.data,
        estimateDetailedBudgetStructure: estimateDetailedBudgetStructure.data,
        estimateEscalationRates: estimateEscalationRates.data,
        estimateIndirectCosts: estimateIndirectCosts.data,
        estimateCostSubFields: estimateCostSubFields.data,
        estimateProgrammingConfigGroups: estimateProgrammingConfigGroups.data,
        formulas: formulas.data
      });

      // Set the redux state for the new calculations
      // This will update the UI right away, before more logic is done below
      dispatch(actions.setCalculations(calculationResult));

      const { updatedDesignFields, updatedCostFields, updatedCostSubFields } = await calculationWorker.getChangedFields({
        calculationResult,
        estimateDesignFields: estimateDesignFields.data,
        estimateCostFields: estimateCostFields.data,
        estimateCostSubFields: estimateCostSubFields.data,
        prevEstimateCostFieldCalculations,
        prevEstimateCostSubFieldCalculations,
        prevEstimateDesignFieldCalculations
      });

      //  If any of the estimate design fields that are driven by formulas have changed, update the local swr state first
      await updateDesignFieldsSWRState(updatedDesignFields);
      // Update the estimate fields in the database
      await handleSaveFields(updatedDesignFields, updatedCostFields, updatedCostSubFields);

      setIsInitialized(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      rollbar.error(err);
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ...dependencies]);

  const debouncedRunCalculations = useDebounce(runCalculations, 50);

  useAsyncEffect(debouncedRunCalculations, dependencies);

  return { isInitialized, isError };
};

const calculationsInitialState = {
  designFieldUpdateCount: 0,
  'sub-field': {},
  field: {},
  formula: {
    'design-field': {}
  },
  'field-group': {},
  bucket: {},
  'bucket-group': {},
  total: {},
  'comp-field': {},
  'comp-bucket': {},
  'comp-escalation': {},
  'comp-msa-premium': {},
  'indirect-cost': {},
  'field-overrides-escalation-rate': 0,
  'total-cost': {}
};

const { actions, reducer } = createSlice({
  name: 'estimateCalculations',
  initialState: calculationsInitialState,
  reducers: {
    setCalculations: (state, action) => {
      state['sub-field'] = action.payload.subFieldCalculations;
      state['formula'] = action.payload.formulaCalculations;
      state.field = action.payload.fieldCalculations;
      state['field-group'] = action.payload.fieldGroupCalculations;
      state['bucket-group'] = action.payload.bucketGroupCalculations;
      state['bucket'] = action.payload.bucketCalculations;
      state['comp-field'] = action.payload.compFieldCalculations;
      state['comp-bucket'] = action.payload.compBucketCalculations;
      state['comp-escalation'] = action.payload.compEscalationCalculations;
      state['comp-msa-premium'] = action.payload.compMsaPremiumCalculations;
      state['indirect-cost'] = action.payload.indirectCostCalculations;
      state['field-overrides-escalation-rate'] = action.payload.fieldsOverridesEscalationRate;
      state['total-cost'] = action.payload.totalCalculations;
    },
    incrementDesignFieldUpdateCount: (state) => {
      state.designFieldUpdateCount += 1;
    },
    resetEstimate: () => {
      return calculationsInitialState;
    }
  }
});

export { useCalculations, reducer, actions };
